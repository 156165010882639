* {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
}

.site-logo {
  width: 95px;
}

.posr-150 {
  position: relative;
  top: -100px;
  margin-bottom: -100px;
}

.posr-255 {
  position: relative;
  top: -250px;
  margin-bottom: -250px;
}

.posr-80 {
  position: relative;
  top: -80px;
  margin-bottom: -80px;
}

.posr-65 {
  position: relative;
  top: -65px;
  margin-bottom: -65px;
}

@media (max-width: 550px) {
  .posr-150,
  .posr-80,
  .posr-65 {
    top: 0;
    margin-bottom: 0;
  }
}

.top-menu {
  font-size: 0.775rem;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  z-index: 10001;
  border-bottom: solid 1px #ffffff;
}

.top-menu a {
  color: #eeeeee;
  transition: ease-in-out color 0.15s;
}

.top-menu a:hover {
  color: #ffffff;
  text-decoration: none;
}

.text-green {
  color: #2aa07e !important;
}

.text-gray {
  color: #999999 !important;
}

.text-mendiblue {
  color: #224e77 !important;
}

.text-oceanblue {
  color: #03344c !important;
}

.text-orange {
  color: #ffac33 !important;
}

.text-skyblue {
  color: #00c3ff !important;
}

.text-red {
  color: #ec0000 !important;
}

.text-heading {
  font-size: 8rem;
  margin-bottom: -31px !important;
}

@media (max-width: 550px) {
  .text-heading {
    padding: 0 !important;
    font-size: 3rem;
    margin-bottom: -11px !important;
  }
}

.center-vh {
  height: 65vh;
  align-items: center;
}

.ssmendiship {
  top: -64px;
  position: absolute;
}

.header-section {
  background: url("./assets/jouwen-wang-1_C_YEx7lCs-unsplash.png") 0 0 no-repeat;
  height: 100vh;
  background-size: cover;
}

.header-section.about {
  background: url("./assets/wind-turbine-against-deep-blue-sky.jpg") 0 center
    no-repeat;
  background-size: cover;
}

.header-section.companies {
  background: url("./assets/cctv-camera-on-an-office-building-wall-PH2X4AN.jpg")
    0 -250px no-repeat;
  background-size: cover;
}

.header-section.services {
  background: url("./assets/industrial-landscape-with-railway.jpg") 0 -150px no-repeat;
  background-size: cover;
}

.header-section.careers {
  background: url("./assets/wind-farm.jpg") 0 center no-repeat;
  background-size: cover;
}

.header-section.news {
  background: url("./assets/cargo-ship-full-loaded.jpg") 0 center no-repeat;
  background-size: cover;
}

.header-section.contact {
  background: url("./assets/mendigroup-gignage.jpg") 0 -250px no-repeat;
  background-size: cover;
}

.main-section {
  position: relative;
  z-index: 1001;
}

.career-section .numbered {
  font-size: 2.5rem;
  font-weight: bold;
}

.news-section .tab-column:hover {
  cursor: pointer;
}

.news-section .tab-column:hover .card-text {
  display: block !important;
}

.news-section .tab-column .card-body {
  background-color: #224e77;
  opacity: 0.5;
}

.news-section .tab-column:hover {
  padding: 0.5rem;
}

.news-section .tab-column:hover .card-body {
  background-color: #03344c;
  opacity: 0.8;
  padding: 1rem;
}

.main-menu a {
  color: #ffffff;
  font-weight: 600;
}

.main-menu a.active {
  color: #ebebeb;
}

.header-content h1 {
  color: #03344c;
  font-size: 2.775rem;
  text-transform: uppercase;
}

.header-content em {
  font-weight: bold;
  font-style: normal;
}

.btn {
  border-radius: 0;
  padding: 0.5rem;
  min-width: 10rem;
}
.btn-learn-more {
  background-color: #2aa07e;
  color: #ffffff;
}

.bg-gray {
  background-color: #999999 !important;
}

.bg-green {
  background-color: #2aa07e !important;
}

.bg-red {
  background-color: #ec0000 !important;
}

.bg-mendiblue {
  background-color: #224e77 !important;
}

.bg-oceanblue {
  background-color: #03344c !important;
}

.bg-orange {
  background-color: #ffac33 !important;
}

.bg-skyblue {
  background-color: #00c3ff !important;
}

.lh-1 {
  line-height: 1;
}

.navi-left:hover,
.scroll-arrow:hover {
  cursor: pointer;
}

.navi-btn {
  width: 64px;
  height: 64px;
  background-color: #2aa07e;
  background: url("./assets/cta-button.png") center center;
  background-size: cover;

  display: flex;
  align-items: center;
  justify-content: center;
}

.navi-card {
  position: absolute;
  right: 0.5em;
  bottom: 0.5em;
  padding: 10px;
}

.para-high {
  font-size: 2.5rem;
  font-weight: 700;
}

.para-med {
  font-size: 1.5rem;
  font-weight: 700;
}

.heading-big {
  font-size: 4.5rem;
  font-weight: 700;
}

@media (max-width: 550px) {
  .heading-big {
    font-size: 3.5rem;
    font-weight: 700;
  }
}

.secondary-content {
  color: #03344c;
}

.client-logo {
  position: relative;
  float: left;
  width: 212px;
  height: 150px;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
  opacity: 0.5;
}

.client-logo:hover {
  cursor: pointer;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 1;
}

.about-ship {
  background: url("./assets/about-ship.png") center 0;
  background-size: cover;
  background-position: center;
}

.col-content {
  color: #f1f1f1;
  height: 500px;
  margin-bottom: 150px;
}
.col-content .heading {
  font-size: 1.5rem;
  min-height: 7rem;
}

.col-content .numbered {
  color: #6c757d;
  font-size: 3.5rem;
  font-weight: 700;
}

.col-content:hover {
  background-color: #00c3ff;
}

.col-content:hover .navi-btn {
  display: flex !important;
}

.col-content:hover .numbered {
  color: #ffffff;
}

.brother-content {
  background-color: #224e77;
  position: relative;
}

h2 {
  font-size: 2rem;
}

.tabbed-nav ul {
  list-style: none;
  margin: 0;
}

.tabbed-nav ul li {
  padding: 0 0.5rem;
}

.tabbed-nav ul li:hover {
  cursor: pointer;
}

.tabbed-nav ul li.active {
  color: #00c3ff;
}

.shipping {
  background: url("./assets/andreas-dittberner-XZnBoEQ46NY-unsplash.jpg") center
    0;
  background-size: cover;
}

.rail {
  background: url("./assets/image0.jpeg") center 0;
  background-size: cover;
}

.aviation {
  background: url("./assets/aviation.jpg") center 0;
  background-size: cover;
}

.energy {
  background: url("./assets/V52_850_Turkana_2.jpg") center 0;
  background-size: cover;
}

.companies-img {
  width: 100%;
  height: 400px;
}

.lft {
  position: relative;
  right: -4rem;
  z-index: 1001;
}

.rgt {
  position: relative;
  left: -4rem;
}

.mendi-rail-company {
  background: url("./assets/mre-company.svg") center 0;
  background-size: cover;
  object-fit: cover;
}

.mendi-trident {
  background: url("./assets/mtrident-company.png") center 0;
  background-size: cover;
  object-fit: cover;
}

.mendi-invest {
  background: url("./assets/mti-company.jpg") center 0;
  background-size: cover;
  object-fit: cover;
}

.go-energy {
  background: url("./assets/GoEnergy-Canopy.jpg") center 0;
  background-size: cover;
  object-fit: cover;
}

.tab-column {
  border: none;
  border-radius: 0;
  background-position: center;
}

.h-250 {
  height: 250px;
}

.h-450 {
  height: 450px;
}

.h-600 {
  height: 600px;
}

.fh {
  height: 100%;
}

.pt-100 {
  padding-top: 100px;
}

.exec-profile {
  width: 330px;
  height: 420px;
}

.exec-profile .heading {
  font-size: 1.2rem;
}

@media (max-width: 550px) {
  .exec-profile {
    width: 300px;
    height: 420px;
    margin-right: 0 !important;
  }
}

.exec-chair {
  background: url("./assets/Mendi6.png") center 0;
  background-size: cover;
}

.exec-ceo {
  background: url("./assets/Mendi9.png") center 0;
  background-size: cover;
}

.exec-sales {
  background: url("./assets/Mendi8.png") center 0;
  background-size: cover;
}

.exec-marketing {
  background: url("./assets/Mendi7.png") center 0;
  background-size: cover;
}

/*.exec-projects {
  background: url("./assets/Mendi10.png") center 0;
  background-size: cover;
}
*/
.companies-card {
  min-height: 500px;
}

.contact-details {
  z-index: 1001;
}

.btn-submit {
  background-color: #00c3ff;
  color: #ffffff;
}

.flex-equal > * {
  -ms-flex: 1;
  flex: 1;
}

@media (min-width: 768px) {
  .flex-md-equal > * {
    -ms-flex: 1;
    flex: 1;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.mapview {
  position: relative;
  height: 768px;
  width: 100%;
  position: relative;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 768px;
  width: 100%;
}

.gm-style .place-card-large {
  display: none !important;
}

.coordinates {
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
  position: relative;
  position: relative;
  z-index: 100;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.social-list li {
  padding: 0 10px;
}

.social-list li:hover {
  cursor: pointer;
}

.fa-icon {
  color: #ffffff;
}

sup {
  color: #ec0000;
}
.server-message {
  font-size: 2rem;
  color: #f1f1f1;
}

.footer-fs {
  font-size: 12px;
}

@media (min-width: 768px) {
  .card-columns {
    column-count: 2;
  }
}

.footer-address {
  position: absolute;
  right: 0;
}

@media (max-width: 768px) {
  .footer-address {
    position: relative;
    right: 0;
  }
}
